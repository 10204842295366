import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import continisTheme from "@continis/theme";

Alpine.plugin(collapse);
Alpine.plugin(continisTheme);

Alpine.data("FileSizeValidator", () => ({
  maxFileSize: 10 * 1024 * 1024, // 10 MB
  errorMessage: gettext("The file is too large. Please upload a file smaller than or equal to 10MB."),
  originalHelpText: undefined,
  helpTextElement: undefined,

  init() {
    this.$el.addEventListener('change', (event) => {
      this.validateSize(event.target.files, event.target);
    });
    this.helpTextElement = this.$el.closest('label').querySelector('.text-gray-500');
    this.originalHelpText = this.helpTextElement.innerText;
  },
  validateSize(files, input) {
    if (files.length > 0) {
      const file = files[0];
      if (file.size > this.maxFileSize) {
        input.setCustomValidity(this.errorMessage);
        input.closest('.input-base').classList.add('input-invalid');
        this.helpTextElement.classList.add('text-red-600');
        this.helpTextElement.innerText = this.errorMessage;
      } else {
        input.setCustomValidity('');
        input.closest('.input-base').classList.remove('input-invalid');
        this.helpTextElement.classList.remove('text-red-600');
        this.helpTextElement.innerText = this.originalHelpText;
      }
    }
  }
}));

window.htmx = require('htmx.org');

Alpine.start();
